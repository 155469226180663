export default [
  {
    path: '/reviews',
    name: 'reviews-index',
    component: () => import('@/views/reviews/List.vue'),
    meta: {
      pageTitle: 'admin.navigation.reviews',
      breadcrumb: [
        {
          text: 'admin.navigation.reviews',
          active: true,
        },
      ],
      resource: 'reviews',
      action: 'index',
    },
  },
  {
    path: '/reviews/show/:id',
    name: 'reviews-show',
    component: () => import('@/views/reviews/Show.vue'),
    meta: {
      pageTitle: 'admin.navigation.reviews',
      breadcrumb: [
        {
          text: 'admin.navigation.reviews',
          to: { name: 'reviews-index' },
        },
        {
          text: 'general.view',
          active: true,
        },
      ],
      resource: 'reviews',
      action: 'show',
    },
  },
]
