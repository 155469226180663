export default [
  {
    path: '/feeds',
    name: 'feeds-index',
    component: () => import('@/views/feeds/List.vue'),
    meta: {
      pageTitle: 'admin.navigation.feeds',
      breadcrumb: [
        {
          text: 'admin.navigation.feeds',
          active: true,
        },
      ],
      resource: 'feeds',
      action: 'index',
    },
  },
  {
    path: '/feeds/create',
    name: 'feeds-store',
    component: () => import('@/views/feeds/Create.vue'),
    meta: {
      pageTitle: 'admin.navigation.feeds',
      breadcrumb: [
        {
          text: 'admin.navigation.feeds',
          to: { name: 'feeds-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'feeds',
      action: 'store',
    },
  },
  {
    path: '/feeds/edit/:id',
    name: 'feeds-update',
    component: () => import('@/views/feeds/Edit.vue'),
    meta: {
      pageTitle: 'admin.navigation.feeds',
      breadcrumb: [
        {
          text: 'admin.navigation.feeds',
          to: { name: 'feeds-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'feeds',
      action: 'update',
    },
  },
]
