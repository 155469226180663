export default [
  {
    path: '/products',
    name: 'products-index',
    component: () => import('@/views/catalog/products/List.vue'),
    meta: {
      pageTitle: 'admin.navigation.products',
      breadcrumb: [
        {
          text: 'admin.navigation.products',
          active: true,
        },
      ],
      resource: 'products',
      action: 'index',
    },
  },
  {
    path: '/products/create',
    name: 'products-store',
    component: () => import('@/views/catalog/products/Create.vue'),
    meta: {
      pageTitle: 'admin.navigation.products',
      breadcrumb: [
        {
          text: 'admin.navigation.products',
          to: { name: 'products-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'products',
      action: 'store',
    },
  },
  {
    path: '/products/edit/:id',
    name: 'products-update',
    component: () => import('@/views/catalog/products/Edit.vue'),
    meta: {
      pageTitle: 'admin.navigation.products',
      breadcrumb: [
        {
          text: 'admin.navigation.products',
          to: { name: 'products-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'products',
      action: 'update',
    },
  },
  {
    path: '/sales',
    name: 'sales-index',
    component: () => import('@/views/catalog/sales/List.vue'),
    meta: {
      pageTitle: 'admin.navigation.sales',
      breadcrumb: [
        {
          text: 'admin.navigation.sales',
          active: true,
        },
      ],
      resource: 'sales',
      action: 'index',
    },
  },
  {
    path: '/sales/create',
    name: 'sales-store',
    component: () => import('@/views/catalog/sales/Create.vue'),
    meta: {
      pageTitle: 'admin.navigation.sales',
      breadcrumb: [
        {
          text: 'admin.navigation.sales',
          to: { name: 'sales-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'sales',
      action: 'store',
    },
  },
  {
    path: '/sales/edit/:id',
    name: 'sales-update',
    component: () => import('@/views/catalog/sales/Edit.vue'),
    meta: {
      pageTitle: 'admin.navigation.sales',
      breadcrumb: [
        {
          text: 'admin.navigation.sales',
          to: { name: 'sales-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'sales',
      action: 'update',
    },
  },
  {
    path: '/stickers',
    name: 'stickers-index',
    component: () => import('@/views/catalog/stickers/List.vue'),
    meta: {
      pageTitle: 'admin.navigation.stickers',
      breadcrumb: [
        {
          text: 'admin.navigation.stickers',
          active: true,
        },
      ],
      resource: 'stickers',
      action: 'index',
    },
  },
  {
    path: '/stickers/create',
    name: 'stickers-store',
    component: () => import('@/views/catalog/stickers/Create.vue'),
    meta: {
      pageTitle: 'admin.navigation.stickers',
      breadcrumb: [
        {
          text: 'admin.navigation.stickers',
          to: { name: 'stickers-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'stickers',
      action: 'store',
    },
  },
  {
    path: '/stickers/edit/:id',
    name: 'stickers-update',
    component: () => import('@/views/catalog/stickers/Edit.vue'),
    meta: {
      pageTitle: 'admin.navigation.stickers',
      breadcrumb: [
        {
          text: 'admin.navigation.stickers',
          to: { name: 'stickers-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'stickers',
      action: 'update',
    },
  },
  {
    path: '/attributes',
    name: 'attributes-index',
    component: () => import('@/views/catalog/attributes/List.vue'),
    meta: {
      pageTitle: 'admin.navigation.attributes',
      breadcrumb: [
        {
          text: 'admin.navigation.attributes',
          active: true,
        },
      ],
      resource: 'attributes',
      action: 'index',
    },
  },
  {
    path: '/attributes/create',
    name: 'attributes-store',
    component: () => import('@/views/catalog/attributes/Create.vue'),
    meta: {
      pageTitle: 'admin.navigation.attributes',
      breadcrumb: [
        {
          text: 'admin.navigation.attributes',
          to: { name: 'attributes-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'attributes',
      action: 'store',
    },
  },
  {
    path: '/attributes/edit/:id',
    name: 'attributes-update',
    component: () => import('@/views/catalog/attributes/Edit.vue'),
    meta: {
      pageTitle: 'admin.navigation.attributes',
      breadcrumb: [
        {
          text: 'admin.navigation.attributes',
          to: { name: 'attributes-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'attributes',
      action: 'update',
    },
  },
  {
    path: '/services',
    name: 'services-index',
    component: () => import('@/views/catalog/services/List.vue'),
    meta: {
      pageTitle: 'admin.navigation.services',
      breadcrumb: [
        {
          text: 'admin.navigation.services',
          active: true,
        },
      ],
      resource: 'stickers',
      action: 'index',
    },
  },
  {
    path: '/services/create',
    name: 'services-store',
    component: () => import('@/views/catalog/services/Create.vue'),
    meta: {
      pageTitle: 'admin.navigation.services',
      breadcrumb: [
        {
          text: 'admin.navigation.services',
          to: { name: 'services-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'stickers',
      action: 'store',
    },
  },
  {
    path: '/services/edit/:id',
    name: 'services-update',
    component: () => import('@/views/catalog/services/Edit.vue'),
    meta: {
      pageTitle: 'admin.navigation.services',
      breadcrumb: [
        {
          text: 'admin.navigation.services',
          to: { name: 'services-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'stickers',
      action: 'update',
    },
  },
]
