export default [
  {
    path: '/coupons',
    name: 'coupons-index',
    component: () => import('@/views/coupons/List.vue'),
    meta: {
      pageTitle: 'admin.navigation.coupons',
      breadcrumb: [
        {
          text: 'admin.navigation.coupons',
          active: true,
        },
      ],
      resource: 'coupons',
      action: 'index',
    },
  },
  {
    path: '/coupons/create',
    name: 'coupons-store',
    component: () => import('@/views/coupons/Create.vue'),
    meta: {
      pageTitle: 'admin.navigation.coupons',
      breadcrumb: [
        {
          text: 'admin.navigation.coupons',
          to: { name: 'coupons-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'coupons',
      action: 'store',
    },
  },
  {
    path: '/coupons/edit/:id',
    name: 'coupons-update',
    component: () => import('@/views/coupons/Edit.vue'),
    meta: {
      pageTitle: 'admin.navigation.coupons',
      breadcrumb: [
        {
          text: 'admin.navigation.coupons',
          to: { name: 'coupons-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'coupons',
      action: 'update',
    },
  },
]
