export default [
  {
    path: '/messages',
    name: 'messages-index',
    component: () => import('@/views/messages/List.vue'),
    meta: {
      pageTitle: 'admin.navigation.messages',
      breadcrumb: [
        {
          text: 'admin.navigation.messages',
          active: true,
        },
      ],
      resource: 'messages',
      action: 'index',
    },
  },
]
